<template>
  <el-container class="home-container">
    <el-aside class="el-aside rr-sidebar hidden-xs-only" style="height: 100%;background-color: #545c64">
        <Header :navActive='navActive'/>
    </el-aside>
    <el-container class="con-right">
      <el-main>
      <Breadcrumb/>

<el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>复制商品</el-breadcrumb-item>
  <el-breadcrumb-item>单品复制</el-breadcrumb-item>
</el-breadcrumb>

<!-- 内容 开始 -->
<el-card class="box-card mt20">

    <!-- 搜索区域 -->
    <el-row :gutter="20">
      <el-col :span="7">
        <el-input
          placeholder="请输入内容"
          v-model="query"
          clearable
          @clear="onClickQuery"
          class="input-with-select">
          <template #append>
            <el-button icon="el-icon-search" @click="onClickQuery"></el-button>
          </template>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="danger" @click="handleDeleteAll">删除</el-button>
      </el-col>
    </el-row>

    <!-- 商品列表区域 -->
    <el-table :data="tableData" stripe style="width: 100%" @selection-change="selectionLineChangeHandle">
      <el-table-column type="selection" width="55"></el-table-column>
      <!-- <el-table-column type="index"></el-table-column> -->
      <!-- <el-table-column label="id" prop="id"></el-table-column> -->
      <el-table-column label="图片" prop="image_url">
        <template #default="scope">
          <el-image
          style="width: 60px; height: 60px"
          :src="scope.row.image_url"
          fit="fit"
          @click="goto(scope.row.new_link_url)"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="标题" prop="title">
       <template #default="scope">
          <p class="text-overflow2" @click="goto(scope.row.new_link_url)">{{scope.row.title}}</p>
        </template>
      </el-table-column>
      <el-table-column label="来源宝贝">
        <template #default="scope">
          <p>{{scope.row.web_name}}</p>
          <p><el-tag size="medium" type="warning" @click="goto(scope.row.link_url)">源地址</el-tag></p>
        </template>
      </el-table-column>
      <el-table-column label="宝贝价格" prop="price"></el-table-column>
      <el-table-column label="状态">
       <template #default="scope">
          <el-tag type="info" v-if="scope.row.state === 1">{{scope.row.state_name}}</el-tag>
          <el-tag type="warning" v-if="scope.row.state === 2">{{scope.row.state_name}}</el-tag>
          <el-tag type="success" v-if="scope.row.state === 3">{{scope.row.state_name}}</el-tag>
          <!-- <el-tag type="danger"  v-if="scope.row.state === 4">{{scope.row.state_name}}</el-tag> -->
          <!-- 错误提示 -->
          <el-popover
            placement="top-start"
            title="提示"
            :width="200"
            trigger="hover"
            :content="scope.row.errorMsg"
            v-if="scope.row.state === 4"
          >
            <template #reference>
              <el-tag type="danger">{{scope.row.state_name}}</el-tag>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="复制时间" prop="create_time"></el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页区域 -->
    <div class="mt20">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</el-card>
      <!-- 内容 结束 -->
    </el-main>
    </el-container>
</el-container>
</template>

<script>
// import { useRouter } from 'vue-router'
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { getLists, deleteByid, deleteAllByid } from 'network/goods'
import Header from 'components/common/header/Header'
import Breadcrumb from 'components/common/breadcrumb/Breadcrumb'

export default defineComponent({
  name: 'List',
  components: {
    Header,
    Breadcrumb
  },
  setup () {
    // const router = useRouter()
    const state = reactive({
      showBtn: false,
      tableData: [],
      dataonLineListSelections: [],
      // 总页数
      total: 0
    })
    const queryInfo = reactive({
      // 搜索关键字
      query: '',
      // 当前状态
      status: 0,
      // 当前的页数
      page: 1,
      // 当前每页显示多少条
      page_size: 10
    })
    // const arrayIds = reactive({
    //   ids: []
    // })

    const goto = (url) => {
      window.open(url, '_blank')
    }

    // 删除单个商品
    const handleDelete = (index, row) => {
      ElMessageBox.confirm('此操作将永久删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          console.log(row.id)
          // 删除复制记录
          deleteByid({ id: row.id }).then(res => {
            if (res.status === 1) {
              dataInfo(queryInfo)
              ElMessage({
                type: 'success',
                message: '删除成功!'
              })
            } else {
              state.tableData = []
            }
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除'
          })
        })
    }

    // 批量删除商品
    const handleDeleteAll = () => {
      ElMessageBox.confirm('此操作将永久删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const arrayPush = []
          for (var i = 0; i < state.dataonLineListSelections.length; i++) {
            // console.log('id:' + state.dataonLineListSelections[i].id)
            arrayPush.push(state.dataonLineListSelections[i].id)
          }
          // 删除复制记录
          deleteAllByid(arrayPush).then(res => {
            // console.log(res)
            if (res.status === 1) {
              dataInfo(queryInfo)
              ElMessage({
                type: 'success',
                message: '删除成功!'
              })
            } else {
              state.tableData = []
            }
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除'
          })
        })
    }

    const selectionLineChangeHandle = (val) => {
      console.log(`当前页: ${val}`)
      state.dataonLineListSelections = val
    }

    const handleSizeChange = (val) => {
      // console.log(`每页 ${val} 条`)
      queryInfo.page_size = val
      dataInfo(queryInfo)
    }
    const handleCurrentChange = (val) => {
      // console.log(`当前页: ${val}`)
      queryInfo.page = val
      dataInfo(queryInfo)
    }
    // 关键词搜索功能
    const onClickQuery = () => {
      dataInfo(queryInfo)
    }

    // 调用数据库里的商品列表
    const dataInfo = (queryInfo) => {
      // 复制记录
      getLists(queryInfo).then(res => {
        if (res.status === 1) {
          state.tableData = res.result.data
          queryInfo.page = res.result.current_page
          queryInfo.page_size = res.result.per_page
          state.total = res.result.total
        } else {
          state.tableData = []
        }
      })
    }
    onMounted(() => {
      dataInfo(queryInfo)
    })
    return {
      ...toRefs(state),
      ...toRefs(queryInfo),
      goto,
      search: '',
      handleDelete,
      handleCurrentChange,
      handleSizeChange,
      dataInfo,
      onClickQuery,
      handleDeleteAll,
      selectionLineChangeHandle,
      navActive: '2-2'
    }
  }
})
</script>
<style lang="scss" scoped>
.el-select .el-input {
    width: 130px;
  }
</style>
